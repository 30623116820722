import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo";
import AboutImage from "../components/about_image";
import ServicesImage from "../components/service_image";
import HealthImage from "../components/health_image";
import ContactImage from "../components/contact_image";

const OurServices = () => (
    <Layout>
        <SEO title="Our Services" description="We can provide the following silo cleaning services - Silo Cleaning, High level cleaning, Conveyor/elevator/mixer/scales cleaning"/>
            <div className={'max-w-screen-lg mx-auto'}>
                    <Logo />
            </div>
            <div className={'bg-white p-4 max-w-screen-lg mx-auto'}>
                    <h1 className={'text-2xl text-indigo-700'}>Services</h1>
                    <div className=" ccm-block-styles">
                            <h2 className={'text-xl text-indigo-700'}>Silo Cleaning</h2>
                            <p className={'my-2'}>
                                    We undertake the interior cleaning of external and internal silos. The cleaning of silos
                                    has had a tendency to be put 'at the back of the queue' – put off until the necessity
                                    is overwhelming maybe due to infestation or blockage. The areas most affected by
                                    product build up and infestation is the roof, dead area and on the discharge hopper
                                    sides and discharge vibrator unit areas. These problems are minimised by regular
                                    cleaning resulting in better hygiene and product flow. A regular schedule of silo
                                    cleaning on your site can be proposed or maybe just on a 'as and when' required basis.
                            </p>
                            <p className={'my-2'}>
                                    Many customers are now requiring traceability of their products and are required to have
                                    a proven hygiene programme in place. On completion of cleaning, a full detailed
                                    report of the condition, structural faults and any other findings is issued for each
                                    silo, if necessary a certificate for each silo cleaned is also available, making
                                    these particularly useful for audit trails.
                            </p>
                    </div>
                    <div className="">
                            <h2 className={'text-xl text-indigo-700'}>High level cleaning</h2>
                            <p className={'my-2'}>The need to carry out high level cleaning of difficult to reach areas is imperative to
                                    the hygiene of food production companies. Whether it is a flour mill, silo building,
                                    warehouse or a food production area, we can access these areas using our specialist
                                    lifting equipment, portable scaffolding or mobile access platforms. Roofs, walls,
                                    supporting steelwork, machinery and silo tops to be cleaned as required.</p></div>
                    <div className=" ccm-block-styles">
                            <h2 className={'text-xl text-indigo-700'}>Filter sleeve/filter cartridge changes</h2>
                            <p className={'my-2'}>Filters and cartridges, especially in the milling industry, need to be changed at regular
                                    intervals to maintain the good working order of silos. We will remove, brush clean
                                    or replace filter sleeves or cartridges, together with the cleaning of the filter
                                    housing. We can supply filter sleeves or cartridges at a competitive price or
                                    customer supplied.</p></div>
                    <div className=" ccm-block-styles">
                            <h2 className={'text-xl text-indigo-700'}>Conveyor/elevator/mixer/scales cleaning</h2>
                            <p className={'my-2'}>The inspection and cleaning of conveyors, elevators, mixers and scales can maintain the
                                    efficient operation of the equipment, prevent cross contamination of different
                                    products and infestation. The inspection and cleaning will highlight any faults and
                                    general wear and tear on parts.</p></div>
            </div>
            <div className={'max-w-screen-lg mx-auto'}>
                    <div className={'flex flex-wrap text-white mt-4'}>
                            <div className={'flex-1 pb-2 my-2 mr-2 bg-yellow-500'}>
                                    <AboutImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>about us</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/about-us">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 bg-orange-500'}>
                                    <ServicesImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>our services</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/our-services">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 bg-teal-700'}>
                                    <HealthImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>health & safety</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/health-and-safety">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 ml-2 bg-indigo-700'}>
                                    <ContactImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>contact us</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/contact-us">More Information</Link>
                                    </div>
                            </div>
                    </div>
            </div>
    </Layout>
)

export default OurServices
